import {
    type ComponentPropsWithoutRef,
    type SyntheticEvent,
    useEffect,
    useRef,
} from "react"
import "./SpyRequest.scss"
import { t } from "@lingui/macro"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { isMobile } from "react-device-detect"
import { Overlay } from "../../common"

interface SpyRequestProps extends ComponentPropsWithoutRef<"div"> {
    standardPrice: number
    fanPrice: number
    isFan: boolean
    requestCallback: () => void
    closeCallback: () => void
    joinCallback: () => void
}

const i18n = {
    fanClubNotice: (percentSaved: number): string => {
        return interpolate(
            gettext("Saving %(percentSaved)s% as a"),
            {
                percentSaved: percentSaved,
            },
            true,
        )
    },
    fanClubMember: "Fan Club member",
    spyTitle: "Spy on Private Show",
    price: "Price: ",
    fanClubPrice: "Fan Club Price: ",
    spyPrice: (price: number): string => {
        return interpolate(
            gettext("%(price)s tokens per minute"),
            {
                price: price,
            },
            true,
        )
    },
    basicUsers: (price: number): string => {
        return interpolate(
            gettext("(Normal Price: %(price)s token per minute)"),
            {
                price: price,
            },
            true,
        )
    },
    fanClubJoin: (percentSaved: number): string => {
        return interpolate(
            gettext("save %(percentSaved)s% "),
            {
                percentSaved: percentSaved,
            },
            true,
        )
    },
    whenSpying: "when spying",
    joinNow: "Join Now",
    cancel: "Cancel",
    start: "Start",
}

// eslint-disable-next-line complexity
export function SpyRequest({
    standardPrice,
    fanPrice,
    isFan,
    requestCallback,
    closeCallback,
    joinCallback,
    ...props
}: SpyRequestProps) {
    const requestSpy = () => {
        requestCallback()
        closeCallback()
    }
    const absorb = (e: SyntheticEvent) => {
        e.stopPropagation()
    }

    useEffect(() => {
        window.addEventListener("popstate", closeCallback)
        return () => {
            window.removeEventListener("popstate", closeCallback)
        }
    }, [closeCallback])

    const cancelButton = useRef<HTMLDivElement>(null)
    const okButton = useRef<HTMLDivElement>(null)
    const joinButton = useRef<HTMLDivElement>(null)

    let noDiscount = false
    if (standardPrice === fanPrice || fanPrice === undefined) {
        noDiscount = true
    }

    useEffect(() => {
        const tabNav = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                closeCallback()
            } else if (e.key === "Tab") {
                e.preventDefault()
                e.stopPropagation()
                switch (document.activeElement) {
                    case cancelButton.current:
                        if (!e.shiftKey) {
                            okButton.current?.focus()
                        } else {
                            if (!isFan && !noDiscount) {
                                joinButton.current?.focus()
                            } else {
                                okButton.current?.focus()
                            }
                        }
                        break
                    case okButton.current:
                        if (!e.shiftKey) {
                            if (!isFan && !noDiscount) {
                                joinButton.current?.focus()
                            } else {
                                cancelButton.current?.focus()
                            }
                        } else {
                            cancelButton.current?.focus()
                        }
                        break
                    default:
                        if (!e.shiftKey) {
                            cancelButton.current?.focus()
                        } else {
                            okButton.current?.focus()
                        }
                        break
                }
            }
        }

        document.addEventListener("keydown", tabNav)
        return () => {
            document.removeEventListener("keydown", tabNav)
        }
    }, [isFan, noDiscount, closeCallback])

    const keyHandler = (e: React.KeyboardEvent) => {
        e.preventDefault()
        if (e.key === "Enter" || e.key === " ") {
            e.target.dispatchEvent(new MouseEvent("click", { bubbles: true }))
        }
    }

    const percentSaved = Math.floor(
        ((standardPrice - fanPrice) / standardPrice) * 100,
    )
    let actualPriceString = i18n.spyPrice(standardPrice)
    if (isFan && !noDiscount) {
        if (fanPrice > 0) {
            actualPriceString = i18n.spyPrice(fanPrice)
        } else {
            actualPriceString = t`Free`
        }
    }
    const starIcon = (
        <div className="starIcon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="16"
                height="16"
                fill="none"
            >
                <rect className="starBox" width="16" height="16" rx="3" />
                <path
                    className="star"
                    d="M12.775 6.67654C12.7121 6.48215 12.5397 6.34409 12.3357 6.3257L9.56492 6.07411L8.46926 3.50961C8.38847 3.32167 8.20449 3.20001 8.00006 3.20001C7.79564 3.20001 7.61165 3.32167 7.53086 3.51005L6.4352 6.07411L3.66394 6.3257C3.46032 6.34453 3.28834 6.48215 3.22513 6.67654C3.16192 6.87093 3.2203 7.08415 3.37433 7.21855L5.46875 9.05536L4.85115 11.7759C4.80596 11.9759 4.8836 12.1827 5.04957 12.3026C5.13878 12.3671 5.24315 12.3999 5.34841 12.3999C5.43916 12.3999 5.52917 12.3754 5.60996 12.3271L8.00006 10.8986L10.3893 12.3271C10.5641 12.4323 10.7845 12.4227 10.9501 12.3026C11.1162 12.1823 11.1937 11.9755 11.1485 11.7759L10.5309 9.05536L12.6254 7.21891C12.7794 7.08415 12.8382 6.8713 12.775 6.67654Z"
                    fill="white"
                />
            </svg>
        </div>
    )
    let classSuffix = isMobile ? " spyMobile" : ""
    if (!isFan && !noDiscount) {
        classSuffix += " notFan"
    }
    const pricePrefix = isFan && !noDiscount ? i18n.fanClubPrice : i18n.price
    const actualPriceClass = isFan && !noDiscount ? "fanPrice" : "basicPrice"
    return (
        <div {...props}>
            <Overlay
                className={`spyRequestOverlay${classSuffix}`}
                onClick={closeCallback}
            >
                <div className="spyRequestCard" onClick={absorb}>
                    {isFan && !noDiscount && (
                        <div className="currentDisc">
                            <div className="fanBanner">
                                <svg
                                    className="horizBanner"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="28"
                                    fill="none"
                                >
                                    <path
                                        className="bannerColor"
                                        d="M0 0H242C244.209 0 246 1.79086 246 4V24C246 26.2091 244.209 28 242 28H0V0Z"
                                        fill="#3B8443"
                                    />
                                </svg>
                                <svg
                                    className="vertBanner"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="5"
                                    height="12"
                                    fill="none"
                                >
                                    <path
                                        d="M0 7.05882L5 12V4.94118L0 0V7.05882Z"
                                        fill="#009900"
                                    />
                                </svg>
                            </div>
                            <div className="fanClubNotice">
                                {starIcon}
                                <div>{i18n.fanClubNotice(percentSaved)}</div>
                                <div className="fanClubMember">
                                    {i18n.fanClubMember}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="defaultDesc">
                        {isFan && !noDiscount && <div className="buffer" />}
                        <div className="spyTitle">{i18n.spyTitle}</div>
                        <div className="spyDesc">
                            <div className="spyPrice">
                                <div>{pricePrefix}</div>
                                <div className={actualPriceClass}>
                                    {actualPriceString}
                                </div>
                            </div>
                            {isFan && !noDiscount && (
                                <div className="basicComparison">
                                    {i18n.basicUsers(standardPrice)}
                                </div>
                            )}
                        </div>
                        {!isFan && !noDiscount && (
                            <div className="fanClubJoin">
                                <div className="fanClubDesc">
                                    {starIcon}
                                    <div className="fanClubDiscountDesc">
                                        <div>{i18n.fanClubMember}s </div>
                                        <div className="discount">
                                            {i18n.fanClubJoin(percentSaved)}
                                        </div>
                                        <div>{i18n.whenSpying}</div>
                                    </div>
                                </div>
                                <div
                                    className="joinButton"
                                    onClick={joinCallback}
                                    ref={joinButton}
                                    tabIndex={0}
                                    onKeyDown={keyHandler}
                                >
                                    {i18n.joinNow}
                                </div>
                            </div>
                        )}
                        <svg
                            className="spyDivider"
                            xmlns="http://www.w3.org/2000/svg"
                            height="2"
                            fill="none"
                            width="100%"
                        >
                            <path
                                className="dividerLine"
                                d="M394 1H0"
                                stroke="#7F7F7F"
                            />
                        </svg>
                        <div className="spyButtonDiv">
                            <div
                                className="spyCancel"
                                onClick={closeCallback}
                                ref={cancelButton}
                                tabIndex={0}
                                onKeyDown={keyHandler}
                            >
                                {i18n.cancel}
                            </div>
                            <div
                                className="spyConfirm"
                                onClick={requestSpy}
                                ref={okButton}
                                tabIndex={0}
                                onKeyDown={keyHandler}
                            >
                                {i18n.start}
                            </div>
                        </div>
                    </div>
                </div>
            </Overlay>
        </div>
    )
}
