import { useState } from "react"
import type { MouseEvent } from "react"
import "./RoomlistFilters.scss"
import {
    DEFAULT_MAX_AGE,
    DEFAULT_MIN_AGE,
} from "@multimediallc/cb-roomlist-prefetch"
import { AgeSection } from "../filterSections/AgeSection"
import { PrivatePriceSection } from "../filterSections/PrivatePriceSection"
import { RegionSection } from "../filterSections/RegionSection"
import { RoomSizeSection } from "../filterSections/RoomSizeSection"
import { MobileFilterNav } from "../MobileFilterNav"
import { ActiveFiltersSection } from "../types"
import type { AgeRange, PrivatePricesOptions, RegionOption } from "../types"
import type { RoomSize } from "@multimediallc/cb-roomlist-prefetch"

interface RoomlistFiltersProps {
    activeSection: ActiveFiltersSection
}

export function RoomlistFilters({ activeSection }: RoomlistFiltersProps) {
    const [overlayedSection, setOverlayedSection] =
        useState<ActiveFiltersSection>(activeSection)
    const [selectedRegions, setSelectedRegions] = useState<Set<RegionOption>>(
        new Set(),
    )
    const [selectedRoomSize, setSelectedRoomSize] = useState<
        RoomSize | undefined
    >(undefined)
    const [selectedPrices, setSelectedPrices] =
        useState<PrivatePricesOptions>("")
    const [selectedAgeRange, setSelectedAgeRange] = useState<AgeRange>({
        minAge: DEFAULT_MIN_AGE,
        maxAge: DEFAULT_MAX_AGE,
    })

    return (
        <div
            className="RoomlistFilters"
            data-testid="roomlist-filters-component"
        >
            <MobileFilterNav
                activeSection={overlayedSection}
                setSectionOverlay={(newActiveSection: ActiveFiltersSection) => {
                    setOverlayedSection(newActiveSection)
                }}
            />
            {overlayedSection !== ActiveFiltersSection.None && (
                <div
                    className="RoomlistFilters__Overlay"
                    data-testid="roomlist-filters-overlay"
                    onClick={(e: MouseEvent<HTMLDivElement>) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setOverlayedSection(ActiveFiltersSection.None)
                    }}
                />
            )}
            {overlayedSection === ActiveFiltersSection.Regions && (
                <RegionSection
                    onHideSection={() => {
                        setOverlayedSection(ActiveFiltersSection.None)
                    }}
                    selectedRegions={selectedRegions}
                    setSelectedRegions={setSelectedRegions}
                />
            )}
            {overlayedSection === ActiveFiltersSection.RoomSize && (
                <RoomSizeSection
                    onHideSection={() => {
                        setOverlayedSection(ActiveFiltersSection.None)
                    }}
                    selectedRoomSize={selectedRoomSize}
                    setSelectedRoomSize={setSelectedRoomSize}
                />
            )}
            {overlayedSection === ActiveFiltersSection.Tags && (
                <div
                    className="RoomlistFilters__SectionPlaceholder"
                    data-testid="tags-mobile-filters-section"
                >
                    Tags
                </div>
            )}
            {overlayedSection === ActiveFiltersSection.PrivatePrices && (
                <PrivatePriceSection
                    onHideSection={() => {
                        setOverlayedSection(ActiveFiltersSection.None)
                    }}
                    selectedPrices={selectedPrices}
                    setSelectedPrices={setSelectedPrices}
                />
            )}
            {overlayedSection === ActiveFiltersSection.Ages && (
                <AgeSection
                    onHideSection={() => {
                        setOverlayedSection(ActiveFiltersSection.None)
                    }}
                    selectedAgeRange={selectedAgeRange}
                    setSelectedAgeRange={setSelectedAgeRange}
                />
            )}
            {overlayedSection === ActiveFiltersSection.Languages && (
                <div
                    className="RoomlistFilters__SectionPlaceholder"
                    data-testid="languages-mobile-filters-section"
                >
                    Languages
                </div>
            )}
        </div>
    )
}
