import type {
    ChangeEvent,
    Dispatch,
    NamedExoticComponent,
    SetStateAction,
} from "react"
import type React from "react"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import { t } from "@lingui/macro"
import "./AgeSection.scss"
import "../filterSection.scss"
import {
    DEFAULT_MAX_AGE,
    DEFAULT_MIN_AGE,
} from "@multimediallc/cb-roomlist-prefetch"
import { CloseButton } from "../../../../../common/atoms/Icons/Others"
import type { AgeRange } from "../../types"

interface AgeSectionProps {
    onHideSection: () => void
    selectedAgeRange: AgeRange
    setSelectedAgeRange: Dispatch<SetStateAction<AgeRange>>
}

export const AgeSection: NamedExoticComponent<AgeSectionProps> =
    memo<AgeSectionProps>(
        ({
            onHideSection,
            selectedAgeRange,
            setSelectedAgeRange,
        }: AgeSectionProps) => {
            const minAgeInputRef = useRef<HTMLInputElement>(null)
            const maxAgeInputRef = useRef<HTMLInputElement>(null)

            const [rawAgeRange, setRawAgeRange] =
                useState<AgeRange>(selectedAgeRange)

            useEffect(() => {
                setRawAgeRange(selectedAgeRange)
            }, [selectedAgeRange])

            const setAgeRanges = useCallback(
                (ageRange: AgeRange) => {
                    setSelectedAgeRange(ageRange)
                },
                [setSelectedAgeRange],
            )

            const minIsInvalid = (newMin: number): boolean => {
                return (
                    isNaN(newMin) ||
                    newMin < DEFAULT_MIN_AGE ||
                    newMin >= DEFAULT_MAX_AGE
                )
            }

            const handleMinAgeInputBlur = useCallback(
                (newMin: number) => {
                    let newMax = rawAgeRange.maxAge

                    // Invalid min value should get reset to the default.
                    if (minIsInvalid(newMin)) {
                        newMin = DEFAULT_MIN_AGE
                    }

                    // If min > max, we assume the user intended the min value they just input, and reset max to default.
                    if (newMin > newMax) {
                        newMax = DEFAULT_MAX_AGE
                    }

                    setAgeRanges({ minAge: newMin, maxAge: newMax })
                },
                [rawAgeRange, setAgeRanges],
            )

            const onMinAgeInput = (
                e: React.KeyboardEvent<HTMLInputElement>,
            ) => {
                if ((e.key ?? "") === "Enter") {
                    if (minAgeInputRef.current !== null) {
                        minAgeInputRef.current.blur()
                    }
                }
                preventNonNumericChars(e)
            }

            const getInputMaxAsNumber = (): number => {
                // The max value requires more care than the min value because "" is a valid input.
                // This method should be used to access the numerical value of the input element for max age.
                const inputValue =
                    maxAgeInputRef.current?.valueAsNumber ?? DEFAULT_MAX_AGE
                const parsedMax = inputValue ?? DEFAULT_MAX_AGE
                if (isNaN(parsedMax)) {
                    return DEFAULT_MAX_AGE
                } else {
                    return parsedMax
                }
            }

            const maxIsInvalid = (newMax: number): boolean => {
                return newMax < DEFAULT_MIN_AGE || newMax > DEFAULT_MAX_AGE
            }

            const handleMaxAgeInputBlur = useCallback(() => {
                let newMin, newMax
                newMax = getInputMaxAsNumber()
                newMin = rawAgeRange.minAge

                // Reset newMax if it is invalid.
                if (maxIsInvalid(newMax)) {
                    newMax = DEFAULT_MAX_AGE
                }
                // If max < min assume the user intended the max value they just inputted, and reset min to default.
                if (newMax < newMin) {
                    newMin = DEFAULT_MIN_AGE
                }

                setAgeRanges({ minAge: newMin, maxAge: newMax })
            }, [rawAgeRange, setAgeRanges])

            const onMaxAgeInput = (
                e: React.KeyboardEvent<HTMLInputElement>,
            ) => {
                if ((e.key ?? "") === "Enter") {
                    if (maxAgeInputRef.current !== null) {
                        maxAgeInputRef.current.blur()
                    }
                }
                preventNonNumericChars(e)
            }

            const resetAgeRange = () => {
                setAgeRanges({
                    minAge: DEFAULT_MIN_AGE,
                    maxAge: DEFAULT_MAX_AGE,
                })
            }

            const isDefaultSelection = useCallback(() => {
                return (
                    selectedAgeRange.minAge === DEFAULT_MIN_AGE &&
                    selectedAgeRange.maxAge === DEFAULT_MAX_AGE
                )
            }, [selectedAgeRange])

            const preventNonNumericChars = (
                evt: React.KeyboardEvent<HTMLInputElement>,
            ) => {
                const keyVal = evt.key ?? ""
                // Length check avoids intercepting special keys such as arrows/tab/enter etc, which have multi-character
                // representations, leaving only printable single characters. Then the isNaN check filters anything other
                // than digits (or space, which is already prevented from being entered in <input type="number">)
                if (keyVal.length === 1 && isNaN(Number(keyVal))) {
                    evt.preventDefault()
                }
            }

            const getInputMaxAsString = useCallback((): string => {
                const rawMaxAge = rawAgeRange.maxAge
                const maxInputStr = isNaN(rawMaxAge) ? "" : rawMaxAge.toString()
                if (
                    maxAgeInputRef.current !== null &&
                    maxAgeInputRef.current === document.activeElement
                ) {
                    // User is actively making changes to input, return input as typed (given that it is numeric).
                    return maxInputStr
                } else {
                    // User is not actively updating input, correct value to empty string when value is DEFAULT_MAX_AGE.
                    return rawMaxAge === DEFAULT_MAX_AGE ? "" : maxInputStr
                }
            }, [rawAgeRange])

            return (
                <div
                    className="FilterSection"
                    data-testid="age-mobile-filters-section"
                >
                    <div className="FilterSection__bar" />
                    <CloseButton
                        className="FilterSection__close-button"
                        onClick={onHideSection}
                        data-testid="age-mobile-filters-section-close-button"
                    />
                    <div className="FilterSection__header">
                        <span className="FilterSection__header-text">{t`Ages`}</span>
                        <button
                            className={`FilterSection__clear-button${isDefaultSelection() ? " FilterSection__clear-button-disabled" : ""}`}
                            data-testid="age-mobile-filters-section-reset"
                            onClick={resetAgeRange}
                        >
                            {t`reset`}
                        </button>
                    </div>
                    <div className="AgeSection__rangedInputContainer">
                        <input
                            data-testid="age-mobile-filters-section-min-age-input"
                            onBlur={(e) => {
                                handleMinAgeInputBlur(e.target.valueAsNumber)
                            }}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setRawAgeRange((prevState) => {
                                    return {
                                        ...prevState,
                                        minAge: e.target.valueAsNumber,
                                    }
                                })
                            }}
                            onKeyDown={onMinAgeInput}
                            ref={minAgeInputRef}
                            type="number"
                            value={rawAgeRange.minAge || ""}
                        />
                        <div className="AgeSection__to">{t`to`}</div>
                        <input
                            data-testid="age-mobile-filters-section-max-age-input"
                            onBlur={() => {
                                handleMaxAgeInputBlur()
                            }}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setRawAgeRange((prevState) => {
                                    return {
                                        ...prevState,
                                        maxAge: e.target.valueAsNumber,
                                    }
                                })
                            }}
                            onKeyDown={onMaxAgeInput}
                            ref={maxAgeInputRef}
                            type="number"
                            value={getInputMaxAsString()}
                        />
                    </div>
                </div>
            )
        },
    )

AgeSection.displayName = "AgeSection"
