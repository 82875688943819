import type { Dispatch, NamedExoticComponent, SetStateAction } from "react"
import { memo, useCallback } from "react"
import "../filterSection.scss"
import { CloseButton } from "../../../../../common/atoms/Icons/Others"
import { RegionOption } from "../../types"

interface RegionSectionProps {
    onHideSection: () => void
    selectedRegions: Set<RegionOption>
    setSelectedRegions: Dispatch<SetStateAction<Set<RegionOption>>>
}

export const RegionSection: NamedExoticComponent<RegionSectionProps> =
    memo<RegionSectionProps>(
        ({
            onHideSection,
            selectedRegions,
            setSelectedRegions,
        }: RegionSectionProps) => {
            const handleRegionTouch = useCallback(
                (region: RegionOption) => {
                    setSelectedRegions((prevSelectedRegions) => {
                        const newSelectedRegions = new Set(prevSelectedRegions)
                        if (prevSelectedRegions.has(region)) {
                            newSelectedRegions.delete(region)
                        } else {
                            newSelectedRegions.add(region)
                        }
                        return newSelectedRegions
                    })
                },
                [setSelectedRegions],
            )

            const clearSelections = useCallback(() => {
                setSelectedRegions(new Set())
            }, [setSelectedRegions])
            return (
                <div
                    className="FilterSection"
                    data-testid="regions-mobile-filters-section"
                >
                    <div className="FilterSection__bar" />
                    <CloseButton
                        className="FilterSection__close-button"
                        onClick={onHideSection}
                        data-testid="mobile-filters-section-close-button"
                    />
                    <div className="FilterSection__header">
                        <span className="FilterSection__header-text">
                            Regions
                        </span>
                        <button
                            onClick={clearSelections}
                            className="FilterSection__clear-button"
                        >
                            Clear all
                        </button>
                    </div>
                    <div className="FilterSection__filterOptions">
                        {Object.values(RegionOption).map((region) => (
                            <button
                                key={region}
                                data-testid={`region-filter-option-${region}`}
                                className={`FilterSection__filterOption ${
                                    selectedRegions.has(region) ? "active" : ""
                                }`}
                                onClick={() => handleRegionTouch(region)}
                            >
                                {region}
                            </button>
                        ))}
                    </div>
                </div>
            )
        },
    )

RegionSection.displayName = "RegionSection"
