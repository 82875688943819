import type { ReactElement, ReactNode } from "react"
import { Trans } from "@lingui/macro"

export type SurveyQuestionType = {
    component: ReactElement | ReactNode
    plainText: string
    questionType: "range" | "text" | "intro" | "outro"
    id: string
    isSubmit?: boolean
}

export type SurveyId = "app_usage_survey"

const BROADCASTER_APP_SURVEY_CONFIG: SurveyQuestionType[] = [
    {
        id: "intro",
        plainText:
            "Help us make CB a better place to stream, and earn 50 tokens for completing a six question survey.",
        component: (
            <Trans>
                Help us make CB a better place to stream, and{" "}
                <b>earn 50 tokens</b> for completing a six question survey.
            </Trans>
        ),
        questionType: "intro",
    },
    {
        id: "1",
        plainText: "It is easy to find apps I need in the App Directory.",
        component: (
            <Trans>It is easy to find apps I need in the App Directory.</Trans>
        ),
        questionType: "range",
    },
    {
        id: "2",
        plainText: "It is easy to install and configure apps once I find them.",
        component: (
            <Trans>
                It is easy to install and configure apps once I find them.
            </Trans>
        ),
        questionType: "range",
    },
    {
        id: "3",
        plainText: "The apps perform reliably during my live streams.",
        component: (
            <Trans>The apps perform reliably during my live streams.</Trans>
        ),
        questionType: "range",
    },
    {
        id: "4",
        plainText: "I would recommend Apps to my friends.",
        component: <Trans>I would recommend Apps to my friends.</Trans>,
        questionType: "range",
    },
    {
        id: "5",
        plainText:
            "What features would you like to see added to the App Directory or the apps themselves? (Optional)",
        component: (
            <Trans>
                What features would you like to see added to the App Directory
                or the apps themselves?{" "}
                <span className="surveyTextSecondary">(Optional)</span>
            </Trans>
        ),
        questionType: "text",
    },
    {
        id: "6",
        plainText:
            "Do you have any additional comments or suggestions to help us improve the App Directory or the apps? (Optional)",
        component: (
            <Trans>
                Do you have any additional comments or suggestions to help us
                improve the App Directory or the apps?{" "}
                <span className="surveyTextSecondary">(Optional)</span>
            </Trans>
        ),
        questionType: "text",
        isSubmit: true,
    },
    {
        id: "outro",
        plainText:
            "Thank you for your response. We have credited your account with 50 tokens.",
        component: (
            <Trans>
                Thank you for your response. We have credited your account with
                50 tokens.
            </Trans>
        ),
        questionType: "outro",
    },
]

export const SURVEY_CONFIGS: Record<SurveyId, SurveyQuestionType[]> = {
    app_usage_survey: BROADCASTER_APP_SURVEY_CONFIG,
}
