import {
    type ComponentPropsWithoutRef,
    type SyntheticEvent,
    useEffect,
    useRef,
    useState,
} from "react"
import "./PremiumPrivateRequest.scss"
import "../../common/atoms/ModalOption/ModalOption.scss"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { Overlay } from "../../common"
import { ModalOption } from "../../common/atoms/ModalOption"

interface PremiumPrivateRequestProps extends ComponentPropsWithoutRef<"div"> {
    tokensPerMinute: number
    minimumMinutes: number
    premiumTokensPerMinute: number
    premiumMinimumMinutes: number
    allowRecording: boolean
    model: string
    toggleCallback: (premium: boolean) => void
    requestCallback: () => void
    closeCallback: () => void
}

const i18n = {
    standard: "Standard Private Show",
    premium: "Premium Private Show",
    defaultDesc: "Private time with the broadcaster",
    recordingDesc: "Recording of this show available in your collection",
    noRecording: "Recording of this show is unavailable",
    rate: (tokens: number, minutes: number): string => {
        return interpolate(
            gettext("%(tokens)s tokens/min, minimum %(minutes)s minutes"),
            { tokens: tokens, minutes: minutes },
            true,
        )
    },
    premiumTooltip: "No one else can spy on the show",
    privateShowTitle: (model: string): string => {
        return interpolate(
            gettext("Private Show with %(model)s"),
            { model: model },
            true,
        )
    },
    cancel: "Cancel",
    request: "Request",
}

export function PremiumPrivateRequest({
    tokensPerMinute,
    minimumMinutes,
    premiumTokensPerMinute,
    premiumMinimumMinutes,
    allowRecording,
    model,
    toggleCallback,
    requestCallback,
    closeCallback,
    ...props
}: PremiumPrivateRequestProps) {
    const [premium, setPremium] = useState<boolean>(false)

    useEffect(() => {
        window.addEventListener("popstate", closeCallback)
        return () => {
            window.removeEventListener("popstate", closeCallback)
        }
    }, [closeCallback])

    const cancelButton = useRef<HTMLDivElement>(null)
    const requestButton = useRef<HTMLDivElement>(null)
    const option1 = useRef<HTMLDivElement>(null)
    const option2 = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const tabNav = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                closeCallback()
            } else if (e.key === "Tab") {
                e.preventDefault()
                e.stopPropagation()
                switch (document.activeElement) {
                    case cancelButton.current:
                        if (!e.shiftKey) {
                            requestButton.current?.focus()
                        } else {
                            if (premiumTokensPerMinute > 0) {
                                option2.current?.focus()
                            } else {
                                option1.current?.focus()
                            }
                        }
                        break
                    case requestButton.current:
                        if (!e.shiftKey) {
                            option1.current?.focus()
                        } else {
                            cancelButton.current?.focus()
                        }
                        break
                    case option1.current:
                        if (!e.shiftKey) {
                            if (premiumTokensPerMinute > 0) {
                                option2.current?.focus()
                            } else {
                                cancelButton.current?.focus()
                            }
                        } else {
                            requestButton.current?.focus()
                        }
                        break
                    default:
                        if (!e.shiftKey) {
                            option1.current?.focus()
                        } else {
                            cancelButton.current?.focus()
                        }
                        break
                }
            }
        }

        document.addEventListener("keydown", tabNav)
        return () => {
            document.removeEventListener("keydown", tabNav)
        }
    }, [premiumTokensPerMinute, closeCallback])

    const keyHandler = (e: React.KeyboardEvent) => {
        e.preventDefault()
        if (e.key === "Enter" || e.key === " ") {
            e.target.dispatchEvent(new MouseEvent("click", { bubbles: true }))
        }
    }

    const absorb = (e: SyntheticEvent) => {
        e.stopPropagation()
    }
    const selectPremium = () => {
        if (!premium) {
            setPremium(true)
            toggleCallback(true)
        }
    }
    const selectStandard = () => {
        if (premium) {
            setPremium(false)
            toggleCallback(false)
        }
    }
    const request = () => {
        requestCallback()
        closeCallback()
    }
    const details = []
    const checkDiv = (
        <svg
            className="checkDiv"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M4.74341 11.8456C5.22032 12.3275 5.9943 12.3404 6.48694 11.8747L13.586 5.17965C13.923 4.87642 14.0695 4.41426 13.9687 3.97204C13.8679 3.52982 13.5357 3.17695 13.1006 3.04999C12.6655 2.92304 12.1959 3.04193 11.8734 3.36065L5.66064 9.21965L4.15091 7.69465C3.83881 7.36856 3.37525 7.23628 2.93826 7.34862C2.50127 7.46096 2.15879 7.80045 2.04234 8.23671C1.9259 8.67297 2.0536 9.13817 2.3764 9.45365L4.74341 11.8456Z"
                fill="#009900"
            />
        </svg>
    )
    const warningDiv = (
        <svg
            className="checkDiv"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0ZM3.5 5C2.94772 5 2.5 5.44772 2.5 6C2.5 6.55228 2.94772 7 3.5 7H8.5C9.05228 7 9.5 6.55228 9.5 6C9.5 5.44772 9.05229 5 8.5 5H3.5Z"
                fill="#B3B3B3"
            />
        </svg>
    )
    details.push(
        <div className="privateDetails" key="defaultDescription">
            {checkDiv}
            <div className="descDiv">{i18n.defaultDesc}</div>
        </div>,
    )
    let recordingSymbol = checkDiv
    let recordingMessage = i18n.recordingDesc
    if (!allowRecording) {
        recordingSymbol = warningDiv
        recordingMessage = i18n.noRecording
    }
    details.push(
        <div className="privateDetails" key="recordingDescription">
            {recordingSymbol}
            <div className="descDiv">{recordingMessage}</div>
        </div>,
    )
    const optionDivs = []
    optionDivs.push(
        <ModalOption
            callback={selectStandard}
            title={i18n.standard}
            description={i18n.rate(tokensPerMinute, minimumMinutes)}
            selected={!premium}
            tabHandle={option1}
            className="sOptionDiv"
            key="standardOption"
            data-testid="private-standard-option"
            onKeyDown={keyHandler}
            tabIndex={0}
        />,
    )
    if (premiumTokensPerMinute > 0) {
        optionDivs.push(
            <ModalOption
                callback={selectPremium}
                title={i18n.premium}
                description={i18n.rate(
                    premiumTokensPerMinute,
                    premiumMinimumMinutes,
                )}
                tabHandle={option2}
                selected={premium}
                className="sOptionDiv"
                key="premiumOption"
                data-testid="private-premium-option"
                tooltip={i18n.premiumTooltip}
                onKeyDown={keyHandler}
                tabIndex={0}
            />,
        )
    }
    return (
        <div {...props}>
            <Overlay
                className="premiumPrivateRequest"
                data-testid="private-request-overlay"
                onClick={closeCallback}
            >
                <div className="premiumRequestModal" onClick={absorb}>
                    <div className="privateTitle">
                        {i18n.privateShowTitle(model)}
                    </div>
                    <div className="detailsDiv">{details}</div>
                    <div className="optionsDiv">{optionDivs}</div>
                    <svg
                        className="divider"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="2"
                    >
                        <path d="M5000 1H0" />
                    </svg>
                    <div className="premiumButtonDiv">
                        <div
                            className="cancelButton"
                            onClick={closeCallback}
                            ref={cancelButton}
                            data-testid="private-cancel-button"
                            onKeyDown={keyHandler}
                            tabIndex={0}
                        >
                            {i18n.cancel}
                        </div>
                        <div
                            className="requestButton"
                            onClick={request}
                            ref={requestButton}
                            data-testid="private-request-button"
                            onKeyDown={keyHandler}
                            tabIndex={0}
                        >
                            {i18n.request}
                        </div>
                    </div>
                </div>
            </Overlay>
        </div>
    )
}
