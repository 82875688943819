import { Trans } from "@lingui/macro"
import "./MobileRoomlistReloadMessage.scss"

interface ReloadMessageProps {
    isShown: boolean
}

export function MobileRoomlistReloadMessage({ isShown }: ReloadMessageProps) {
    if (!isShown) {
        return null
    }
    return (
        <p className="MobileRoomlistReloadMessage">
            <Trans>
                Something went wrong while loading the rooms. Please{" "}
                <a onClick={() => window.location.reload()}>refresh the page</a>{" "}
                to try again.
            </Trans>
        </p>
    )
}
