import type { Dispatch, NamedExoticComponent, SetStateAction } from "react"
import { memo, useCallback } from "react"
import "../filterSection.scss"
import { RoomSize } from "@multimediallc/cb-roomlist-prefetch"
import { CloseButton } from "../../../../../common/atoms/Icons/Others"

export const ROOM_SIZE_MAPPING = new Map<RoomSize, string>([
    [RoomSize.SMALL, "Intimate"],
    [RoomSize.MEDIUM, "Mid-Sized"],
    [RoomSize.LARGE, "High-Traffic"],
])

interface RoomSizeSectionProps {
    onHideSection: () => void
    selectedRoomSize: RoomSize | undefined
    setSelectedRoomSize: Dispatch<SetStateAction<RoomSize | undefined>>
}

export const RoomSizeSection: NamedExoticComponent<RoomSizeSectionProps> =
    memo<RoomSizeSectionProps>(
        ({
            onHideSection,
            selectedRoomSize,
            setSelectedRoomSize,
        }: RoomSizeSectionProps) => {
            const handleRoomSizeTouch = useCallback(
                (roomSize: RoomSize | undefined) => {
                    setSelectedRoomSize(
                        (prevRoomSize: RoomSize | undefined) => {
                            if (roomSize === prevRoomSize) {
                                return
                            }
                            return roomSize
                        },
                    )
                },
                [setSelectedRoomSize],
            )

            const clearSelections = useCallback(() => {
                setSelectedRoomSize(undefined)
            }, [setSelectedRoomSize])
            return (
                <div
                    className="FilterSection"
                    data-testid="roomsize-mobile-filters-section"
                >
                    <div className="FilterSection__bar" />
                    <CloseButton
                        className="FilterSection__close-button"
                        onClick={onHideSection}
                        data-testid="mobile-filters-section-close-button"
                    />
                    <div className="FilterSection__header">
                        <span className="FilterSection__header-text">
                            Room Size
                        </span>
                        <button
                            onClick={clearSelections}
                            className="FilterSection__clear-button"
                        >
                            Clear
                        </button>
                    </div>
                    <div className="FilterSection__filterOptions">
                        {Object.values(RoomSize).map((roomSize) => (
                            <button
                                key={roomSize}
                                className={`FilterSection__filterOption ${
                                    selectedRoomSize === roomSize
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => handleRoomSizeTouch(roomSize)}
                            >
                                {ROOM_SIZE_MAPPING.get(roomSize)}
                            </button>
                        ))}
                    </div>
                </div>
            )
        },
    )

RoomSizeSection.displayName = "RoomSizeSection"
