import { Trans } from "@lingui/macro"
import "./MobileSearchMessaging.scss"

interface MobileSearchMessagingProps {
    numRooms: number
    keywords?: string
}

export function MobileSearchMessaging({
    numRooms,
    keywords,
}: MobileSearchMessagingProps) {
    if (numRooms !== 0) {
        return null
    }
    return (
        <h2 className="MobileSearchMessaging">
            {keywords !== undefined ? (
                <Trans>
                    No search results for &lsquo;{keywords}&rsquo; match your
                    preferences, please update your{" "}
                    <a id="search_filter_options_link" href="#">
                        search/filter options.
                    </a>
                </Trans>
            ) : (
                <Trans>
                    No search results match your preferences, please update your{" "}
                    <a id="search_filter_options_link" href="#">
                        search/filter options.
                    </a>
                </Trans>
            )}
        </h2>
    )
}
