import { t } from "@lingui/macro"
import { Sizes } from "../../../../interfaces/sizing"
import { colorClass } from "../../../../utils/css"
import Button, { ButtonColor } from "../../atoms/Button"
import { Textarea } from "../../atoms/Textarea"
import { Typography } from "../../atoms/Typography"
import type { SurveyQuestionType } from "./constants"

import "./SurveyType.scss"

type SurveyTypeProps = {
    question: SurveyQuestionType
    selectedVal?: string
    onBack?: () => void
    onNext: () => void
    totalQuestions: number
}

const SurveyQuestion = ({
    question,
    totalQuestions,
}: {
    question: SurveyQuestionType
    totalQuestions?: number
}) => {
    const { component, id } = question
    const showCounter = id !== "intro" && id !== "outro"

    return (
        <div className="surveyTitleWrapper">
            {showCounter && (
                <Typography
                    size={Sizes.xspx}
                    color={colorClass.textBodySecondary}
                    className="surveyCounter"
                >{t`Question ${id} of ${totalQuestions}`}</Typography>
            )}
            <Typography size={Sizes.mdpx} className="surveyQuestion">
                {component}
            </Typography>
        </div>
    )
}

type SurveyTypeRangeProps = {
    onRatingClick: (id: string, value: string) => void
} & SurveyTypeProps
export function SurveyTypeRange({
    question,
    onRatingClick,
    selectedVal,
    onBack,
    onNext,
    totalQuestions,
}: SurveyTypeRangeProps) {
    const { id } = question
    return (
        <div className="surveyTypeRange">
            <SurveyQuestion
                question={question}
                totalQuestions={totalQuestions}
            />
            <div className="ratingContainer">
                <div className="ratingContainer__buttons">
                    {[
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                    ].map((num) => (
                        <Button
                            key={num}
                            className={`ratingButton${
                                selectedVal === num ? " selected" : ""
                            }`}
                            onClick={() => onRatingClick(id, num)}
                            text={num.toString()}
                        />
                    ))}
                </div>

                <div className="ratingContainer__helperText">
                    <Typography
                        size={Sizes.xs}
                        color={colorClass.textBodySecondary}
                    >
                        {t`Strongly disagree`}
                    </Typography>
                    <Typography
                        size={Sizes.xs}
                        color={colorClass.textBodySecondary}
                    >{t`Strongly agree`}</Typography>
                </div>
            </div>

            <div className="surveyActionButtons">
                <Button
                    className="surveyActionButtons__buttonBack"
                    onClick={onBack}
                    text={t`Back`}
                    color={ButtonColor.Link}
                />
                {selectedVal && (
                    <Button
                        className="surveyActionButtons__buttonNext"
                        onClick={onNext}
                        text={t`Next`}
                    />
                )}
            </div>
        </div>
    )
}

type SurveyTypeTextProps = {
    onChange: (id: string, value: string) => void
} & SurveyTypeProps
export function SurveyTypeText({
    question,
    onNext,
    onBack,
    selectedVal,
    onChange,
    totalQuestions,
}: SurveyTypeTextProps) {
    const { id, isSubmit } = question
    const nextButtonText = Boolean(isSubmit) ? t`Submit` : t`Next`
    return (
        <div className="surveyTypeText">
            <SurveyQuestion
                question={question}
                totalQuestions={totalQuestions}
            />
            <Textarea
                className="surveyTypeText__textarea"
                rows={4}
                value={selectedVal}
                onChange={(e) => onChange(id, e.target.value)}
                maxLength={5000}
            />
            <div className="surveyActionButtons">
                <Button
                    className="surveyActionButtons__buttonBack"
                    onClick={onBack}
                    text={t`Back`}
                    color={ButtonColor.Link}
                />
                <Button
                    className="surveyActionButtons__buttonNext"
                    onClick={onNext}
                    text={nextButtonText}
                />
            </div>
        </div>
    )
}

export function SurveyTypeOutro({ question, onNext }: SurveyTypeProps) {
    return (
        <div className="surveyTypeOutro">
            <SurveyQuestion question={question} />
            <div className="surveyActionButtons">
                <Button
                    className="surveyActionButtons__button"
                    onClick={onNext}
                    text={t`Done`}
                />
            </div>
        </div>
    )
}

export function SurveyIntroMain({ question, onBack, onNext }: SurveyTypeProps) {
    return (
        <div className="surveyIntroMain">
            <SurveyQuestion question={question} />
            <div className="surveyActionButtons">
                <Button
                    className="surveyActionButtons__button"
                    onClick={onBack}
                    text={t`No thanks`}
                    color={ButtonColor.Link}
                />

                <Button
                    className="surveyActionButtons__button"
                    onClick={onNext}
                    text={t`Sure, I'll give feedback`}
                />
            </div>
        </div>
    )
}
