import { isLanguageFilterActive } from "@multimediallc/cb-roomlist-prefetch"
import "./MobileFilterNav.scss"
import { HomepageFiltersIcon } from "../../../filters/HomepageFiltersIcon"
import { ActiveFiltersSection } from "../types"

interface MobileFilterNavProps {
    setSectionOverlay: (activeSection: ActiveFiltersSection) => void
    activeSection: ActiveFiltersSection
}

export function MobileFilterNav({
    setSectionOverlay,
    activeSection,
}: MobileFilterNavProps) {
    const showLanguageFilters = isLanguageFilterActive()
    return (
        <div className="MobileFilterNav" data-testid="mobile-filters-nav">
            <div className="MobileFilterNav__HomepageFiltersIconContainer">
                <HomepageFiltersIcon hasSelectedFilters={false} />
            </div>
            <nav className="MobileFilterNav__Nav">
                <button
                    className={`MobileFilterNav__Button${activeSection === ActiveFiltersSection.Tags ? " selected" : ""}`}
                    data-testid="tags-filter-nav-button"
                    onClick={() => {
                        setSectionOverlay(ActiveFiltersSection.Tags)
                    }}
                >
                    Tags
                </button>
                <div className="MobileFilterNav__Spacer" />
                <button
                    className={`MobileFilterNav__Button${activeSection === ActiveFiltersSection.Regions ? " selected" : ""}`}
                    data-testid="regions-filter-nav-button"
                    onClick={() => {
                        setSectionOverlay(ActiveFiltersSection.Regions)
                    }}
                >
                    Regions
                </button>
                <div className="MobileFilterNav__Spacer" />
                <button
                    className={`MobileFilterNav__Button${activeSection === ActiveFiltersSection.RoomSize ? " selected" : ""}`}
                    data-testid="room-size-filter-nav-button"
                    onClick={() => {
                        setSectionOverlay(ActiveFiltersSection.RoomSize)
                    }}
                >
                    Room Size
                </button>
                <div className="MobileFilterNav__Spacer" />
                <button
                    className={`MobileFilterNav__Button${activeSection === ActiveFiltersSection.PrivatePrices ? " selected" : ""}`}
                    data-testid="private-prices-filter-nav-button"
                    onClick={() => {
                        setSectionOverlay(ActiveFiltersSection.PrivatePrices)
                    }}
                >
                    Private Show (tokens/min)
                </button>
                <div className="MobileFilterNav__Spacer" />
                <button
                    className={`MobileFilterNav__Button${activeSection === ActiveFiltersSection.Ages ? " selected" : ""}`}
                    data-testid="ages-filter-nav-button"
                    onClick={() => {
                        setSectionOverlay(ActiveFiltersSection.Ages)
                    }}
                >
                    Ages
                </button>
                <div className="MobileFilterNav__Spacer" />
                {showLanguageFilters && (
                    <>
                        <button
                            className={`MobileFilterNav__Button${activeSection === ActiveFiltersSection.Languages ? " selected" : ""}`}
                            data-testid="languages-filter-nav-button"
                            onClick={() => {
                                setSectionOverlay(
                                    ActiveFiltersSection.Languages,
                                )
                            }}
                        >
                            Languages
                        </button>
                        <div className="MobileFilterNav__Spacer" />
                    </>
                )}
            </nav>
        </div>
    )
}
