import type { Dispatch, NamedExoticComponent, SetStateAction } from "react"
import { memo, useCallback } from "react"
import "../filterSection.scss"
import { PrivatePrices } from "@multimediallc/cb-roomlist-prefetch"
import { CloseButton } from "../../../../../common/atoms/Icons/Others"
import type { PrivatePricesOptions } from "../../types"

interface PrivatePriceSectionProps {
    onHideSection: () => void
    selectedPrices: PrivatePricesOptions
    setSelectedPrices: Dispatch<SetStateAction<PrivatePricesOptions>>
}

export const privatePriceToButtonText = new Map<PrivatePrices, string>([
    [PrivatePrices.Value6, "6 tks"],
    [PrivatePrices.Range12to18, "12 - 18 tks"],
    [PrivatePrices.Range30to42, "30 - 42 tks"],
    [PrivatePrices.Range60to72, "60 - 72 tks"],
    [PrivatePrices.Range90Plus, "90+ tks"],
])

export const PrivatePriceSection: NamedExoticComponent<PrivatePriceSectionProps> =
    memo<PrivatePriceSectionProps>(
        ({
            onHideSection,
            selectedPrices,
            setSelectedPrices,
        }: PrivatePriceSectionProps) => {
            const handlePrivatePriceTouch = useCallback(
                (privatePrice: PrivatePricesOptions) => {
                    setSelectedPrices((prevSelectedPrices) => {
                        return prevSelectedPrices === privatePrice
                            ? ""
                            : privatePrice
                    })
                },
                [setSelectedPrices], // dependency not strictly necessary, but added for clarity and ESlint
            )

            const isSelectionNull = useCallback(() => {
                return selectedPrices === ""
            }, [selectedPrices])

            const clearSelections = useCallback(() => {
                setSelectedPrices("")
            }, [setSelectedPrices])

            return (
                <div
                    className="FilterSection"
                    data-testid="private-prices-mobile-filters-section"
                >
                    <div className="FilterSection__bar" />
                    <CloseButton
                        className="FilterSection__close-button"
                        onClick={onHideSection}
                        data-testid="mobile-filters-section-close-button"
                    />
                    <div className="FilterSection__header">
                        <span className="FilterSection__header-text">
                            Private Show (tokens per minute)
                        </span>
                        <button
                            onClick={clearSelections}
                            className={`FilterSection__clear-button${isSelectionNull() ? " FilterSection__clear-button-disabled" : ""}`}
                        >
                            Clear
                        </button>
                    </div>
                    <div className="FilterSection__filterOptions">
                        {Object.entries(PrivatePrices).map(
                            ([key, privatePrice]) => (
                                <button
                                    key={key}
                                    className={`FilterSection__filterOption ${
                                        selectedPrices === privatePrice
                                            ? "active"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        handlePrivatePriceTouch(privatePrice)
                                    }
                                >
                                    {privatePriceToButtonText.get(privatePrice)}
                                </button>
                            ),
                        )}
                    </div>
                </div>
            )
        },
    )

PrivatePriceSection.displayName = "PrivatePriceSection"
