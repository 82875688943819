import type { PrivatePrices } from "@multimediallc/cb-roomlist-prefetch"

export enum ActiveFiltersSection {
    None = "",
    Regions = "regions",
    Tags = "tags",
    RoomSize = "roomSize",
    PrivatePrices = "privatePrices",
    Ages = "ages",
    Languages = "languages",
}

export enum RegionOption {
    NorthAmerica = "North America",
    SouthAmerica = "South America",
    Asia = "Asia",
    EuroRussia = "Euro/Russia",
    Others = "Others",
}

export interface AgeRange {
    minAge: number
    maxAge: number
}

export type PrivatePricesOptions = PrivatePrices | ""
