import { t } from "@lingui/macro"
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { Gender } from "@multimediallc/gender-utils"
import "./MobileGendersBanner.scss"

interface MobileGendersBannerProps {
    currentGender?: Gender
    isShown: boolean
}

const getOptionsList = (): [string, Gender][] => [
    [t`Women`, Gender.Female],
    [t`Men`, Gender.Male],
    [t`Couples`, Gender.Couple],
    [t`Trans`, Gender.Trans],
]

export function MobileGendersBanner({
    currentGender,
    isShown,
}: MobileGendersBannerProps) {
    if (!isShown) {
        return null
    }

    const getHref = (gender: Gender): string => {
        const targetGenders = gender === currentGender ? [] : [gender]
        return UrlState.current.getURLForPartialState({
            genders: targetGenders,
        })
    }

    const onGenderChange = (gender: Gender): void => {
        const targetGenders = gender === currentGender ? [] : [gender]
        UrlState.current.setPartialState({ genders: targetGenders })
    }

    return (
        <div
            className="MobileGendersBanner"
            data-testid="mobile-genders-banner"
        >
            {getOptionsList().map(([text, gender]) => {
                const isActive = gender === currentGender
                return (
                    <a
                        className={`gender${isActive ? " active" : ""}`}
                        data-testid={`mobile-gender-tab-${gender}`}
                        href={getHref(gender)}
                        key={gender}
                        onClick={(e) => {
                            if (e.ctrlKey || e.metaKey || e.shiftKey) {
                                return
                            }
                            e.preventDefault()
                            onGenderChange(gender)
                        }}
                    >
                        {text}
                    </a>
                )
            })}
        </div>
    )
}
